import { string } from 'prop-types';
import React from 'react';
import { post } from '../http-api';
//import { withRouter } from 'react-router-dom';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

//import {withRouter} from 'react-router-dom';
class Subscription extends React.Component{
    constructor(props){
        super(props);
        this.state={
           refcode:" "
        };
    }

    componentDidMount(params){
        
        localStorage.clear();
        var refcode="";

        var f= window.location.search.substring(1);
        console.log(window.location+'--------------------'+f);

        var url=window.location.pathname;

        for (var i=0;i<f.length;i++){
            
            if(f[i]== '='){
                refcode = "";
                continue;
            }
            else{ 
                 refcode = refcode +f[i];
            }
        }
        console.log('---------------------'+refcode)


        const payload = new FormData();
        payload.append('refcode', refcode);
        post('directLogin', payload) .then(res => {
            if(res.data.success){
                localStorage.setItem('JWT', res.data.data.JWT);
                localStorage.setItem('user_details', JSON.stringify(res.data.data.user_details));
                localStorage.setItem('userDetailsSplash', JSON.stringify(res.data.data.user_details));
                this.setState({ logining: false, msisdn: '', password: ''});
                this.props.history.goBack();
                this.props.history.push('/')
            } else {
                Swal(res.data.message,'','error');
                this.setState({logining: false});
            }
        }).catch (function (error) {
            if (error.message === "Network Error") {
                Swal(error.message, '', 'error').then((result)=>{
                    window.location.reload();
                });
            }
        });
  

    }
    
    render(){
        return(
            <div style={{height:"300px",width:'100%'}}>
            <center style={{marginTop:'50%'}}>{this.state.uuid}Please wait....</center>
            </div>
        )
    }
}
export default withRouter(Subscription);