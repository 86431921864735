
import Loadable from 'react-loadable';
import LandingLoader from '../loader/landingLoader';

export const Home = Loadable({
    loader: () => import( /* webpackChunkName: "home" */ './home'),
    loading: LandingLoader
});

export const Login = Loadable({
    loader: () => import( /* webpackChunkName: "login" */ './login'),
    loading: LandingLoader
});
export const Subscribe = Loadable({
    loader: () => import( /* webpackChunkName: "login" */ './Subscribe'),
    loading: LandingLoader
});


export const Register = Loadable({
    loader: () => import( /* webpackChunkName: "register" */ './register'),
    loading: LandingLoader
});

export const FrequentlyAskedQuestions = Loadable({
    loader: () => import( /* webpackChunkName: "frequentlyAskedQuestions" */ './frequently-asked-questions'),
    loading: LandingLoader
});

export const PrivacyPolicy = Loadable({
    loader: () => import( /* webpackChunkName: "privacyPolicy" */ './privacy-policy'),
    loading: LandingLoader
});

export const TermsOfService = Loadable({
    loader: () => import( /* webpackChunkName: "termOfService" */ './terms-of-service'),
    loading: LandingLoader
});

