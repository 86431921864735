import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PrivateRoute from '../components/private-route';
import DisableAuthRoute from '../components/disable-auth-route';
import { Home, Login, Register,Subscribe, FrequentlyAskedQuestions, PrivacyPolicy, TermsOfService } from './lazy';
import Reward from './reward';
import Quiz from './quiz';
import Profiles from './profiles';
import Language from './language';
import LeaderBoard from './leaderboard';
import ChangePassword from './change-password';
import Subscription from './Subscription';

import AudioTest from './AudioTest';

import './index.css';

const Routes = ({ location }) => (
      <Switch>
        <Route exact path='/' component={Home} />
        <DisableAuthRoute path='/login' component={Login} />
        <DisableAuthRoute path='/register' component={Register} />
        <DisableAuthRoute path='/subscribe' component={Subscribe} />
        <PrivateRoute path='/profile' component={Profiles} />
        <Route path='/quiz' component={Quiz} />
        <Route path='/rewards' component={Reward} />
        <Route path='/leaderboard' component={LeaderBoard} />
        <Route exact path='/lang' component={Language} />
        <Route path='/frequently-asked-questions' component={FrequentlyAskedQuestions} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-service" component={TermsOfService} />
        <Route path='/change-password' component={ChangePassword} />
        <Route path='/subscription/logi:id' component={Subscription} />
        <Route path="/terms/logi:id" component={TermsOfService} />
        <Route path="/refcode:id" component={Subscription} />
        <Route path="/refcode/:id" component={Subscription} />
        {/* extra route for testing */}
        <Route path="/audio-test" component={AudioTest} />
        <Route render={()=>(<Redirect to="/" />)} />
      </Switch>
);


export default withRouter(Routes);